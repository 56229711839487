import React from "react";
import { ItemModel } from "../models/ItemModel";
import ListGroupItem from "react-bootstrap/esm/ListGroupItem";
import {
    getAxiosPaymentInstance,
    numberToMoneyFormat,
} from "../components/helper";
import { TransactionWidget } from "./TransactionWidget";
import { PaymentWidget } from "./PaymentWidget";

type CheckoutProperties = {
    token: string;
    billNo: string;
};

export class CheckoutWidget extends React.Component<CheckoutProperties> {
    state = { transaction: undefined as any };
    items: ItemModel[];

    async componentDidMount() {
        await this.loadTransactionDetails();
    }

    constructor(props: any) {
        super(props);
        // init default items
        let item = new ItemModel("", "Dummy", 10000, "");
        this.items = [];
        this.items.push(item);
    }

    renderItems = () => {
        let buffer = [];
        for (let item of this.items) {
            buffer.push(
                <ListGroupItem key={item.name}>
                    {item.name}: Rp. {numberToMoneyFormat(item.price)}
                </ListGroupItem>
            );
        }
        return buffer;
    };

    onPaymentChannelUpdate = (paymentChannelId: number) => {
        this.setState({
            isFormReady: true,
            payment_channel_id: paymentChannelId,
        });
    };

    loadTransactionDetails = async () => {
        const billNo = this.props.billNo;
        const instance = getAxiosPaymentInstance(this.props.token);
        const endpoint = `/transactions/details?bill_no=` + billNo;
        try {
            const res = await instance.get(endpoint);
            const result = res.data.result;
            this.setState({
                transaction: result,
            });
        } catch (exc) {
            console.error("Error at transactionDetails");
            console.error(exc);
        }
    };

    renderTransactionDetails = () => {
        if (this.state.transaction === undefined) {
            return <div>Loading Transaction Details</div>;
        } else {
            return (
                <TransactionWidget
                    transaction={this.state.transaction}
                ></TransactionWidget>
            );
        }
    };

    onTransactionUpdate = (updatedTransaction: any) => {
        const currentTrans = { ...this.state.transaction };
        if (currentTrans) {
            currentTrans.bill_total = updatedTransaction.bill_total;
            currentTrans.payment_channel_id =
                updatedTransaction.payment_channel_id;
        }
        this.setState({
            transaction: currentTrans,
        });
    };

    renderPayment() {
        if (this.state.transaction) {
            return (
                <PaymentWidget
                    transaction={this.state.transaction}
                    onTransactionUpdate={this.onTransactionUpdate}
                ></PaymentWidget>
            );
        } else {
            return <div>Loading Payment Info</div>;
        }
    }

    render() {
        return (
            <div>
                <small>Items</small>
                {this.renderTransactionDetails()}
                <small>Payment Method</small>
                <div className="mb-3">{this.renderPayment()}</div>
            </div>
        );
    }
}
