import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion, Button } from "react-bootstrap";

export type HowToType = {
    title: string;
    contents: string[];
};

type Props = {
    howTo: HowToType[];
    vaNumber?: string;
};

export default function DefaultHowToWidget({ howTo, vaNumber }: Props) {
    const [currentVAKey, setCurrentVAKey] = useState(0);
    return (
        <>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    Silahkan melakukan pembayaran <b>tepat</b> sesuai dengan
                    total tagihan.
                </li>
                <li>
                    Silahkan melakukan <b>pembayaran sebelum batas waktu</b>{" "}
                    yang ditentukan.
                </li>
                <li>
                    <b>Tidak disarankan</b> bayar melalui bank lain agar
                    transaksi dapat diproses tanpa kendala.
                </li>
            </ul>
            <div id="bcaHowTo">
                <Accordion defaultActiveKey="0">
                    {howTo.map((o, i) => {
                        return (
                            <div key={`howto-${i}`}>
                                <div className="accordion-wrapper">
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={i.toString()}
                                        onClick={() => {
                                            setCurrentVAKey(i);
                                        }}
                                    >
                                        {o.title}
                                        <FontAwesomeIcon
                                            icon={
                                                currentVAKey === i
                                                    ? faAngleUp
                                                    : faAngleDown
                                            }
                                        />
                                    </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse eventKey={i.toString()}>
                                    <div>
                                        <ol>
                                            {o.contents.map(
                                                (description, j) => {
                                                    let _desc = vaNumber
                                                        ? description.replaceAll(
                                                              "{vaNumber}",
                                                              vaNumber
                                                          )
                                                        : description;
                                                    return (
                                                        <li
                                                            key={`${i}-desc-${j}`}
                                                        >
                                                            {_desc}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ol>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        );
                    })}
                </Accordion>
            </div>
        </>
    );
}
