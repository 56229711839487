import React from "react";
import { getAxiosPaymentInstance } from "../components/helper";
import { TransactionModel } from "../models/TransactionModel";
import { PaymentWidget } from "./PaymentWidget";
import { TransactionWidget } from "./TransactionWidget";

type TransactionHistoriesProperties = {
    token: string;
};

export class TransactionHistoriesWidget extends React.Component<TransactionHistoriesProperties> {
    state = {
        isLoadingHistories: false,
        transactions: [] as any[],
        isLoadingRepay: false,
        isError: false,
    };
    constructor(props: any) {
        super(props);
        this.state.isLoadingHistories = true;
    }

    getTransactionHistories = async () => {
        const instance = getAxiosPaymentInstance(this.props.token);
        try {
            const res = await instance.get("/transactions");
            const transactions: any[] = res.data.result;
            this.setState({
                isLoadingHistories: false,
                transactions: transactions,
                isError: false,
            });
        } catch (exc) {
            console.error("Error getting transactions");
            console.error(exc);
            this.setState({
                isError: true,
            });
        }
    };

    componentDidMount() {
        this.getTransactionHistories();
    }

    renderTransactionStatus = (status: string) => {
        var badge: string = "badge badge-";
        switch (status) {
            case "PAID":
                badge += "success";
                break;
            case "WAITING":
                badge += "warning";
                break;
            case "CANCELED":
            case "EXPIRED":
                badge += "danger";
                break;
            default:
                badge += "secondary";
        }
        return <small className={badge}>{status}</small>;
    };

    onTransactionUpdate = (updatedTransaction: TransactionModel) => {
        const transactions = this.state.transactions;
        const currentTrans = transactions.find(
            (o) => o.bill_no === updatedTransaction.bill_no
        );
        if (currentTrans) {
            currentTrans.bill_total = updatedTransaction.bill_total;
        }
        this.setState({
            transaction: transactions,
        });
    };

    render() {
        if (this.state.isError) {
            return <div className="text-center">Request Failed.</div>;
        } else if (this.state.isLoadingHistories) {
            return (
                <div className="text-center">
                    Loading Transaction Histories...
                </div>
            );
        } else {
            return (
                <div>
                    {this.state.transactions.map((transaction: any) => {
                        return (
                            <div className="mb-3" key={transaction.bill_no}>
                                <TransactionWidget
                                    transaction={transaction}
                                ></TransactionWidget>
                                <PaymentWidget
                                    transaction={transaction}
                                    onTransactionUpdate={
                                        this.onTransactionUpdate
                                    }
                                ></PaymentWidget>
                            </div>
                        );
                    })}
                </div>
            );
        }
    }
}
