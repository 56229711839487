export class ItemModel {
    id: string;
    name: string;
    price: number;
    qty: number = 1;
    notes: string;
    churchId?: string;

    constructor(id: string, name: string, price: number, notes: string, qty?: number, churchId?: string) {
        this.id = id;
        this.name = name;
        this.price = price;
        this.notes = notes;
        if (qty) {
            this.qty = qty;
        }
        if (churchId) {
            this.churchId = churchId;
        }
    }
}