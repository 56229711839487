import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion, Button } from "react-bootstrap";

type Props = {
    vaNumber: string;
};

export default function BCAHowToWidget({ vaNumber }: Props) {
    const [currentVAKey, setCurrentVAKey] = useState(0);

    return (
        <>
            <ul style={{ paddingLeft: "15px" }}>
                <li>
                    Silahkan melakukan pembayaran <b>tepat</b> sesuai dengan
                    total tagihan.
                </li>
                <li>
                    Silahkan melakukan <b>pembayaran sebelum batas waktu</b>{" "}
                    yang ditentukan.
                </li>
                <li>
                    <b>Tidak disarankan</b> bayar melalui bank lain agar
                    transaksi dapat diproses tanpa kendala.
                </li>
            </ul>
            <div id="bcaHowTo">
                <Accordion defaultActiveKey="0">
                    <div>
                        <div className="accordion-wrapper">
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                onClick={() => {
                                    setCurrentVAKey(0);
                                }}
                            >
                                ATM BCA{" "}
                                <FontAwesomeIcon
                                    icon={
                                        currentVAKey === 0
                                            ? faAngleUp
                                            : faAngleDown
                                    }
                                />
                            </Accordion.Toggle>
                        </div>
                        <Accordion.Collapse eventKey="0">
                            <div>
                                <ol>
                                    <li>Masukkan kartu ATM BCA & Pin</li>
                                    <li>
                                        Pilih menu Transaksi Lainnya &gt;
                                        Transfer &gt; ke Rekening BCA Virtual
                                        Account
                                    </li>
                                    <li>
                                        Masukkan nomor Virtual Account{" "}
                                        {vaNumber}
                                    </li>
                                    <li>
                                        Di halaman konfirmasi, pastikan detil
                                        pembayaran sudah sesuai seperti No VA,
                                        Nama, Perus/Produk dan Total Tagihan
                                    </li>
                                    <li>
                                        Masukkan Jumlah Transfer sesuai dengan
                                        Total Tagihan
                                    </li>
                                    <li>
                                        Ikuti instruksi untuk menyelesaikan
                                        transaksi
                                    </li>
                                    <li>
                                        Simpan struk transaksi sebagai bukti
                                        pembayaran
                                    </li>
                                </ol>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div>
                        <div className="accordion-wrapper">
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="1"
                                onClick={() => {
                                    setCurrentVAKey(1);
                                }}
                            >
                                m-BCA (BCA mobile){" "}
                                <FontAwesomeIcon
                                    icon={
                                        currentVAKey === 1
                                            ? faAngleUp
                                            : faAngleDown
                                    }
                                />
                            </Accordion.Toggle>
                        </div>
                        <Accordion.Collapse eventKey="1">
                            <div>
                                <ol>
                                    <li>
                                        Lakukan log in pada aplikasi BCA Mobile
                                    </li>
                                    <li>
                                        Pilih menu m-BCA, kemudian masukkan kode
                                        akses m-BCA
                                    </li>
                                    <li>
                                        Pilih m-Transfer &gt; BCA Virtual
                                        Account
                                    </li>
                                    <li>
                                        Pilih dari Daftar Transfer, atau
                                        masukkan nomor Virtual Account{" "}
                                        {vaNumber}
                                    </li>
                                    <li>Masukkan pin m-BCA</li>
                                    <li>
                                        Pembayaran selesai. Simpan notifikasi
                                        yang muncul sebagai bukti pembayaran
                                    </li>
                                </ol>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div>
                        <div className="accordion-wrapper">
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="2"
                                onClick={() => {
                                    setCurrentVAKey(2);
                                }}
                            >
                                Internet Banking BCA{" "}
                                <FontAwesomeIcon
                                    icon={
                                        currentVAKey === 2
                                            ? faAngleUp
                                            : faAngleDown
                                    }
                                />
                            </Accordion.Toggle>
                        </div>
                        <Accordion.Collapse eventKey="2">
                            <div>
                                <ol>
                                    <li>
                                        Login pada alamat Internet Banking BCA
                                        (https://klikbca.com)
                                    </li>
                                    <li>
                                        Pilih menu Pembayaran Tagihan &gt;
                                        Pembayaran &gt; BCA Virtual Account
                                    </li>
                                    <li>
                                        Pada kolom kode bayar, masukkan nomor
                                        Virtual Account {vaNumber}
                                    </li>
                                    <li>
                                        Di halaman konfirmasi, pastikan detil
                                        pembayaran sudah sesuai seperti Nomor
                                        BCA Virtual Account, Nama Pelanggan dan
                                        Jumlah Pembayaran
                                    </li>
                                    <li>Masukkan password dan mToken</li>
                                    <li>
                                        Cetak/simpan struk pembayaran BCA
                                        Virtual Account sebagai bukti pembayaran
                                    </li>
                                </ol>
                            </div>
                        </Accordion.Collapse>
                    </div>
                    <div>
                        <div className="accordion-wrapper">
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="3"
                                onClick={() => {
                                    setCurrentVAKey(3);
                                }}
                            >
                                Kantor Bank BCA{" "}
                                <FontAwesomeIcon
                                    icon={
                                        currentVAKey === 3
                                            ? faAngleUp
                                            : faAngleDown
                                    }
                                />
                            </Accordion.Toggle>
                        </div>
                        <Accordion.Collapse eventKey="3">
                            <div>
                                <ol>
                                    <li>
                                        Ambil nomor antrian transaksi Teller dan
                                        isi slip setoran
                                    </li>
                                    <li>
                                        Serahkan slip dan jumlah setoran kepada
                                        Teller BCA
                                    </li>
                                    <li>
                                        Teller BCA akan melakukan validasi
                                        transaksi
                                    </li>
                                    <li>
                                        Simpan slip setoran hasil validasi
                                        sebagai bukti pembayaran
                                    </li>
                                </ol>
                            </div>
                        </Accordion.Collapse>
                    </div>
                </Accordion>
            </div>
        </>
    );
}
