import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PaymentCallback } from "./pages/PaymentCallback";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import { getToken } from "./components/auth";
const querystring = require("query-string");

function App() {
    const parsed = querystring.parse(window.location.search);
    if (parsed.access_token) {
        console.debug("relogin");
        return <Login></Login>;
    } else {
        const token = getToken();
        if (!token) {
            console.debug("Calling login");
            return <Login></Login>;
        }
    }
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={() => <Home />} />
                    <Route
                        exact
                        path="/payment-callback"
                        component={() => <PaymentCallback />}
                    />
                    <Route
                        exact
                        path="/not-found"
                        component={() => <NotFound />}
                    />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
