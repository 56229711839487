import React from "react";
import { CheckoutWidget } from '../widgets/CheckoutWidget';
import { getRedirectUrl, getToken } from '../components/auth';
import { Login } from "./Login";
import { TransactionHistoriesWidget } from "../widgets/TransactionHistoriesWidget";
const querystring = require('query-string');

export class Home extends React.Component {
    renderBackButton() {
        const url = getRedirectUrl();
        if (url) {
            return (
                <div>
                    <a href={url} className="btn btn-outline-success btn-block">Kembali ke halaman registrasi</a>
                </div>)
        } else {
            return <div></div>
        };
    }

    render() {
        const token = getToken();
        if (!token) {
            return <Login></Login>;
        }
        const parsed = querystring.parse(window.location.search);
        if (parsed.bill_no) {
            return (
                <div className="container mt-3 mb-3">
                    <h3 className="text-center">Payment</h3>
                    <CheckoutWidget token={token} billNo={parsed.bill_no}></CheckoutWidget>
                    {this.renderBackButton()}
                </div>)
        } else {
            return (
                <div className="container mt-3">
                    <small>Transaction Histories</small>
                    <TransactionHistoriesWidget token={token}></TransactionHistoriesWidget>
                </div>)
        }
    }
}