interface UserToken {
    person_id?: string;
    token: string;
}

export function resetToken() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('redirect_url');
}

export function getToken() {
    const token = sessionStorage.getItem('token');
    return token || undefined
}

export function setToken(userToken: UserToken) {
    sessionStorage.setItem('token', userToken.token)
}

export function setRedirectUrl(redirectUrl: string) {
    sessionStorage.setItem('redirect_url', redirectUrl);
}

export function getRedirectUrl() {
    let url = sessionStorage.getItem('redirect_url');
    if (!url) {
        url = ''; // default sementara untuk persiapan event aog conference
    }
    return url;
}