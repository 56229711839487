import React from "react";
import { setRedirectUrl, setToken } from "../components/auth";
import axios from "axios";
const querystring = require("query-string");

export class Login extends React.Component {
    loggingIn: boolean;
    billNo?: string;
    constructor(props: any) {
        super(props);
        this.loggingIn = false;
        const parsed = querystring.parse(window.location.search);
        this.billNo = parsed.bill_no;
    }

    loginUser = async () => {
        if (this.loggingIn) {
            return;
        }
        this.loggingIn = true;
        const url = process.env.REACT_APP_ACCOUNTS_OAUTH_URL || ""; // harus string
        window.location.href = url;
    };

    componentDidMount() {
        // direct access token
        const parsed = querystring.parse(window.location.search);
        // kalau ada redirect_url nya juga
        if (parsed.redirect_url) {
            if (
                parsed.redirect_url.includes("/api/payment/callback") &&
                process.env.REACT_APP_EVENT_MAKER_URL
            ) {
                setRedirectUrl(
                    process.env.REACT_APP_EVENT_MAKER_URL + "/events"
                );
            } else {
                setRedirectUrl(parsed.redirect_url);
            }
        } else {
            // manual set redirect
            if (
                process.env.REACT_APP_EVENT_MAKER_URL &&
                this.billNo &&
                this.billNo.indexOf("/GMS-RUN") > 0
            ) {
                setRedirectUrl(
                    process.env.REACT_APP_EVENT_MAKER_URL + "/events"
                );
            }
        }
        if (parsed.access_token) {
            setToken({
                token: parsed.access_token,
            });
            if (this.billNo) {
                window.location.replace("/?bill_no=" + this.billNo);
            } else {
                window.location.href = "/";
            }
        } else if (parsed.auth_code) {
            // auth code dari GMS Accounts
            this.authenticate(parsed.auth_code);
        } else {
            console.debug("Redirect to gms account...");
            this.loginUser();
        }
    }

    authenticate = async (authCode: string) => {
        // ambil access token
        const apiUrl = process.env.REACT_APP_PAYMENT_API_URL + "/auth";
        try {
            const res = await axios.post(apiUrl, {
                accessToken: authCode,
            });
            setToken(res.data.result);
            window.location.href = "/";
        } catch (exc) {
            console.error("Error at login authentication");
            console.error(exc);
        }
    };

    render() {
        const parsed = querystring.parse(window.location.search);
        if (parsed.access_token || parsed.auth_code) {
            return (
                <div className="container mt-3">
                    <div className="text-center">Authenticating...</div>
                </div>
            );
        } else {
            return (
                <div className="container mt-3">
                    <div className="text-center">
                        Redirecting to login page...
                    </div>
                </div>
            );
        }
    }
}
