import moment from "moment";
import { ItemModel } from "./ItemModel";

export class TransactionModel {
    public bill_no: string;
    public trx_id?: string;
    public status: string;
    public bill_at: moment.Moment;
    public expired_at?: moment.Moment;
    public paid_at?: moment.Moment;
    public misc_fee?: number;
    public bill_total: number;
    public bill_description: string;
    public payment_channel_id: number;
    public payment_channel_name: string;
    public payment_receipt_url?: string;
    public is_bank_transfer: boolean;
    public qrcode_url: string = "";
    public items: ItemModel[];
    constructor(data: any) {
        this.bill_no = data.bill_no;
        this.trx_id = data.trx_id;
        this.status = data.status;
        if (data.misc_fee) {
            this.misc_fee = data.misc_fee
        }
        this.bill_at = moment(data.bill_at);
        this.expired_at = moment(data.expired_at);
        this.paid_at = data.paid_at ? moment(data.paid_at) : undefined;
        this.bill_total = data.bill_total;
        this.bill_description = data.bill_description;
        this.payment_channel_id = data.payment_channel_id;
        this.payment_channel_name = data.payment_channel_name;
        this.is_bank_transfer = data.is_bank_transfer;
        if (data.payment_receipt_url) {
            this.payment_receipt_url = data.payment_receipt_url
        }
        if (data.qrcode_url) {
            this.qrcode_url = data.qrcode_url;
        }
        this.items = [];
        for (const item of data.items) {
            this.items.push(new ItemModel(item.id, item.name, item.price, item.notes, item.qty, item.church_id));
        }
    }
}