import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./scss/custom.scss";
import { BrowserRouter } from "react-router-dom";
import messagesEN from "./lang/en.json";

const messages: any = {
    en: messagesEN,
};

let params = new URLSearchParams(document.location.search);
const _lang = params.get("lang");
const allowedLanguages = ["id", "en"];
let language = sessionStorage.getItem("lang") ?? "id";
if (_lang && language !== _lang && allowedLanguages.includes(_lang)) {
    language = _lang;
    sessionStorage.setItem("lang", language);
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <IntlProvider
                messages={messages[language]}
                locale={language}
                defaultLocale="id"
            >
                <App />
            </IntlProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
