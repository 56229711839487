import React from "react";
import { getRedirectUrl, getToken } from "../components/auth";
import { Redirect } from 'react-router-dom';
import { getAxiosPaymentInstance } from "../components/helper";
import { TransactionWidget } from "../widgets/TransactionWidget";
import { PaymentWidget } from "../widgets/PaymentWidget";
const querystring = require('query-string');

export class PaymentCallback extends React.Component {
    state = { transaction: undefined as any };
    paymentStatus: number;
    billNo: string;

    constructor(props: any) {
        super(props);
        const parsed = querystring.parse(window.location.search);
        this.billNo = parsed.bill_no;
        this.paymentStatus = +parsed.status; // diubah ke integer
    }

    async componentDidMount() {
        if (this.billNo) {
            await this.loadTransactionDetails();
        }
    }

    renderBackButton() {
        const url = getRedirectUrl();
        if (url) {
            return (
                <div>
                    <a href={url} className="btn btn-outline-success btn-block">Kembali ke halaman registrasi</a>
                </div>)
        } else {
            return <div></div>
        };
    }

    loadTransactionDetails = async () => {
        const billNo = this.billNo;
        const token = getToken();
        const instance = getAxiosPaymentInstance(token);
        const endpoint = `/transactions/details?bill_no=` + billNo;
        try {
            const res = await instance.get(endpoint);
            const result = res.data.result;
            this.setState({
                transaction: result
            })
        } catch (exc: any) {
            if (exc.response) {
                if (exc.response.status === 404) {
                    this.setState({
                        transaction: null
                    })
                }
            } else {
                console.error("Error at transactionDetails");
                console.error(exc);
            }
        }
    }

    renderTransactionDetail() {
        if (this.state.transaction === undefined) {
            return (<div>Loading Transaction Detail</div>);
        } else if (this.state.transaction === null) {
            return (<div>Transaction not found!</div>);
        } else {
            return <TransactionWidget transaction={this.state.transaction}></TransactionWidget>;
        }
    }

    renderPayment() {
        if (this.state.transaction) {
            return <PaymentWidget onTransactionUpdate={this.onTransactionUpdate} transaction={this.state.transaction}></PaymentWidget>;
        } else {
            return <div>Loading Payment Info</div>;
        }
    }

    onTransactionUpdate = (updatedTransaction: any) => {
        const currentTrans = this.state.transaction;
        if (currentTrans) {
            currentTrans.bill_total = updatedTransaction.bill_total;
        }
        this.setState({}); // refresh state
    }

    renderCallbackStatus() {
        if (this.paymentStatus !== 2) {
            return <div className="alert alert-danger">Payment failed, please try again or change to other payment method</div>;
        } else {
            return <div></div>;
        }
    }

    render() {
        const parsed = querystring.parse(window.location.search);
        const billNo = parsed.bill_no;
        if (!billNo) {
            return <Redirect to="/not-found"></Redirect>
        }
        return (<div className="container mt-3">
            {this.renderCallbackStatus()}
            <small>Item Detail</small>
            <div className="mb-3">
                {this.renderTransactionDetail()}
            </div>
            <small>Payment Info</small>
            <div className="mb-3">
                {this.renderPayment()}
            </div>
            {this.renderBackButton()}
        </div>)
    }
}