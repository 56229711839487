import axios, { AxiosInstance } from "axios";
import { isAndroid } from "react-device-detect";
import Swal from "sweetalert2";
import { getToken, resetToken } from "./auth";
const querystring = require('query-string');
declare const window: any;

export type PaymentChannel = {
    id: number;
    name: string;
    category: string;
    description: string;
    provider: string;
    generate_unique_code: boolean;
    is_bank_transfer: boolean;
    logo_url?: string;
    howto_url?: string;
}

export type ChurchVA = {
    church_id: string;
    va_number: string;
};


var _paymentChannels: PaymentChannel[];

export function numberToMoneyFormat(x: number) {
    let isNegative = false;
    if (x < 0) {
        isNegative = true;
        x *= -1;
    }
    let s = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isNegative) {
        s = `(${s})`
    }
    return s;
}

export function getAxiosPaymentInstance(token?: string): AxiosInstance {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_PAYMENT_API_URL,
        headers: {
            token: token
        }
    })
    instance.interceptors.response.use((response) => response, (error) => {
        if (error.response?.status === 403) { // kemungkinan session expired, clear token lalu kembalikan ke halaman awal
            resetToken();
            Swal.fire("Session Expired", "Please re-login", "error").then(() => {
                const parsed = querystring.parse(window.location.search);
                window.location.href = '/?bill_no=' + parsed.bill_no;
            });
        }
        throw error;
    });
    return instance
}

export async function paymentChannels(): Promise<PaymentChannel[]> {
    if (_paymentChannels === undefined) {
        _paymentChannels = [];
        let url = process.env.REACT_APP_PAYMENT_API_URL + "/payment-channels";
        console.debug("Getting channels from: " + url)
        try {
            const res = await axios.get(url);
            const result = res.data;
            for (let channel of result.result) {
                channel.description = `${channel.name}`;
                // if (channel.category === "DIRECT") {
                //     channel.description = `${channel.name} (Dicek Otomatis)`;
                // } else {
                //     channel.description = `${channel.name}`;
                // }
                _paymentChannels.push(channel);
            }
        } catch (exc) {
            console.log("Error getting payment channels")
            console.log(exc)
        }
    }
    return _paymentChannels;
}

export async function churchesVA(): Promise<ChurchVA[] | undefined> {
    const instance = getAxiosPaymentInstance(getToken());
    let rows: ChurchVA[] | undefined;
    try {
        const rawResult = await instance.get("/churches/va")
        rows = rawResult.data.result;
    } catch (exc) {
        console.error(exc);
    }
    return rows;
}

export function isApp() {
    return !!window.flutter_inappwebview;
}

export function canShowCopyToClipboard() {
    if (isApp()) {
        if (isAndroid) {
            return true;
        }
    }
    return true;
}

export function copyToClipboard(text: string) {
    if (!canShowCopyToClipboard()) {
        return;
    }
    if (isAndroid && isApp()) {
        window.flutter_inappwebview
            .callHandler("copyToClipboard", text)
            .then(() => {
                Swal.fire("Text Copied", "", "success");
            });
    } else {
        navigator.clipboard.writeText(text);
        Swal.fire("Text Copied", "", "success");
    }
};